import { Route, Routes, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { getProductsPurchaseInformation, getUserInfo } from '../../actions/auth'

import { useAppDispatch, useAppSelector } from 'hooks'
import AccountOverview from './AccountOverview'
import AdminPermissions from './AdminPermissions'
import BuyOven from './BuyOven'
import CurrentTermEditMeals from './menu/CurrentTermEditMeals'
import EditUser from './EditUser'
import RefundHistoryPage from './RefundHistoryPage'
import UserCalendar from './menu/UserCalendar'
import UserMealCredits from './UserMealCredits'
import UserMenu from './menu/UserMenu'
import UserNav from './UserNav'
import UserPayment from './UserPayment'
import UserPromotions from './UserPromotions'
import UserShipment from './UserShipment'
import UserSubscription from './UserSubscription'
import ViewUser from './ViewUser'
import ViewUserHistory from './ViewUserHistory'

const User = (): JSX.Element => {
  const { userid: userIDParam = '' } = useParams<{ userid: string }>()
  const userID = getValidUserID(userIDParam)

  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.auth.user)

  useEffect(() => {
    if (userID) {
      dispatch(getUserInfo(userID)).then((response) => {
        if (response && response.payload) {
          dispatch(getProductsPurchaseInformation(userID))
        }
      })
    }
  }, [dispatch, userID])

  if (!userID) {
    return (
      <p>
        The user ID of &quot;{userIDParam}&quot; is not valid (user IDs are
        expected to be numbers). Please check the URL and reload the page.
      </p>
    )
  }

  return (
    <div className="flex space-x-8">
      <div className="w-1/4 shrink-0">
        <UserNav />
      </div>

      <div className="w-3/4">
        {user && (
          <Routes>
            <Route
              element={<AccountOverview user={user} />}
              path="account-overview"
            />
            <Route element={<ViewUser user={user} />} path="view" />
            <Route element={<EditUser user={user} />} path="edit" />
            <Route element={<UserSubscription />} path="meal-plan" />
            <Route element={<UserMenu user={user} />} path="menu" />
            <Route element={<UserMenu user={user} />} path="menu/:termid" />
            <Route
              element={<CurrentTermEditMeals user={user} />}
              path="edit-past-meals/:termid"
            />
            <Route element={<UserCalendar user={user} />} path="calendar" />
            <Route element={<UserPayment user={user} />} path="payment" />
            <Route element={<UserShipment user={user} />} path="shipping" />
            <Route
              element={<RefundHistoryPage user={user} />}
              path="refund-history"
            />
            <Route
              element={<UserMealCredits user={user} />}
              path="account-balance/:tab"
            />
            <Route
              element={<ViewUserHistory user={user} />}
              path="order-history"
            />
            <Route element={<BuyOven user={user} />} path="buy-oven" />
            <Route element={<UserPromotions user={user} />} path="promos" />
            <Route
              element={<AdminPermissions user={user} />}
              path="admin-permissions"
            />
          </Routes>
        )}
      </div>
    </div>
  )
}

export default User

/**
 * Attempts to parse the provided userID string as a number and validates
 * that it's valid. If it is, a number is returned. Otherwise, null is returned.
 */
function getValidUserID(userID: string) {
  const userIDNum = Number(userID)

  return !Number.isNaN(userIDNum) && userIDNum > 0 ? userIDNum : null
}
